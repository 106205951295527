.gradient-container {
  border-radius: 10px;
  padding: 50px;
  min-height: 100vh;
  background-color: #ffffff;
  background-image: radial-gradient(circle, #e2e2e2 1px, rgba(236, 236, 236, 0) 1px);
  background-size: 14px 14px;
}

.gradient-container h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.gradient-container > p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.gradient-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.gradient-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-item-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c1c1c;
  border-radius: 4px;
  padding: 4px;
  margin: 6px;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

.white-link {
  color: white !important;
}

.gradient-item-text:hover {
  opacity: 0.8;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nav-item {
  cursor: pointer;
  margin: 5px;
}

.nav-item.active {
  font-weight: bold;
}

.social-icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.social-icon {
  margin: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .gradient-container {
    padding: 30px;
  }

  .gradient-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .gradient-nav-item {
    margin-bottom: 10px;
  }

  .social-icons {
    margin-top: 20px;
  }
}