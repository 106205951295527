@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  margin: 0;
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  background-color: #ffffff;
  background-image: radial-gradient(circle, #e2e2e2 1px, #ececec00 1px);
  background-size: 14px 14px;
}

.thoughts-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #f9f9f9;
  font-family: 'Inter', sans-serif;
}

.thoughts-sidebar {
  width: 25%;
  padding: 15px;
  background-color: #fff;
  border-right: 1px solid #ddd;
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.thoughts-list {
  list-style: none;
  padding: 0;
}

.thought-item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.thought-item:hover {
  background-color: #f0f0f0;
}

.thought-info {
  display: flex;
  flex-direction: column;
}

.thought-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.thought-tags {
  margin-top: 5px;
}

.thought-tag {
  display: inline-block;
  background-color: #e0e0e0;
  padding: 3px 8px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 0.9em;
}

.thought-details {
  width: 75%;
  padding: 20px;
  background-color: #fff;
}

.back-button {
  padding: 10px 15px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #0056b3;
}

.thought-content h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.thought-content p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.thought-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .thoughts-container {
    flex-direction: column;
  }

  .thoughts-sidebar {
    width: 100%;
    height: auto;
    max-height: 50vh;
  }

  .thought-details {
    height: 50vh;
  }
}
