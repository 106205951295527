@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

body {
  font-family: 'Roboto Mono', monospace;
  background-color: #ffffff;
}

.project-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.back-button {
  font-family: 'Roboto Mono', monospace;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

}

.logo-image {
  max-width: 200px;
  height: auto;
}

.divider {
  border: none;
  border-top: 1px dashed #000000;
  margin: 20px 0;
}

.metadata-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
}

.metadata-item {
  display: flex;
  flex-direction: column;
}

.metadata-label {
  font-weight: 700;
  color: #000000;
}

.metadata-value {
  color: #B7B7B7;
}

.about-section {
  margin-bottom: 20px;
}

.section-label {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
}

.about-text {
  color: #ff4500;
}

.image-container {
  position: relative;
  margin-bottom: 20px;
}

.full-width-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
}

.overlay-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: lowercase;
}

.overlay-subtitle {
  font-size: 18px;
}

.overview-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000000;
}

.overview-text {
  color: #B7B7B7;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}
.overview-text {
  color: #B7B7B7;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.challenge-solution-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.challenge-column, .solution-column {
  width: 48%;
}

.section-text {
  color: #000000;
  font-size: 14px;
  line-height: 1.6;
}

.quote-section {
  margin-bottom: 20px;
}

.quote-text {
  font-style: italic;
  color: #000000;
  font-size: 14px;
  line-height: 1.6;
}

.quote-text {
  font-style: italic;
  color: #000000;
  font-size: 14px;
  line-height: 1.6;
}

.sitemap-section,
.logo-wordmark-section,
.typography-section {
  margin-bottom: 40px;
}

.image-placeholder {
  width: 100%;
  height: 300px;
  background-color: #000000;
  margin-bottom: 20px;
}

.section-text {
  color: #B7B7B7;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .metadata-row {
    flex-wrap: wrap;
  }

  .metadata-item {
    width: 50%;
    margin-bottom: 10px;
  }

  .overlay-title {
    font-size: 24px;
  }

  .overlay-subtitle {
    font-size: 16px;
  }

  .challenge-solution-section {
    flex-direction: column;
  }

  .challenge-column, .solution-column {
    width: 100%;
    margin-bottom: 20px;
  }
  .image-placeholder {
    height: 200px;
  }
}