/* Global styles applied to both mobile and desktop */
.tools-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background: #ffffff;
}

.tools-sidebar {
    width: 300px;
    background: #ffffff;
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
}

.tools-list {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    overflow-y: auto;
}

.tools-list li {
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;  /* Align items to the top */
}

.tool-info {
    flex: 1;
}

.tool-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

.tool-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.tool-tags {
    display: flex;
    flex-wrap: wrap;
}

.tool-tags span {
    background: #e0e0e0;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 12px;
    color: #555;
}

.tools-list li:hover {
    background-color: #f5f5f5;
}

.tools-list li.selected {
    background-color: #f5f5f5;
}

.tools-list li.selected .tool-tags span {
    background-color: #333;
    color: #ffffff;
}

.tool-details {
    flex: 1;
    padding: 30px;
    background: #ffffff;
    overflow-y: auto;
    height: calc(100vh - 60px);
}

.search-bar {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    box-sizing: border-box;
}

/* New styles for the favicon */
.tool-favicon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    object-fit: contain;
    margin-right: 8px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .tools-container {
        flex-direction: column;
    }

    .tools-sidebar {
        width: 100%;
        height: auto;
        padding: 15px;
    }

    .tool-details {
        height: auto;
        padding: 20px;
    }

    .search-bar {
        margin-bottom: 15px;
    }

    .tools-list li {
        margin-bottom: 15px;
        flex-direction: row;
    }

    .tool-info {
        margin-left: 10px;
    }
}
