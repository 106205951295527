/* Global styles applied to both mobile and desktop */
.designs-container {
    display: flex;
    height: 100vh;
    flex-direction: column; /* Stack elements vertically on mobile */
}

.designs-sidebar {
    width: 100%; /* Full width on mobile */
    height: auto; /* Allow height to adjust based on content */
    max-height: 50vh; /* Limit height on mobile */
    overflow-y: auto;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px;
    z-index: 2;
    background-color: white;
}

.designs-list {
    list-style-type: none;
    padding: 0;
}

.designs-list li {
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.designs-list li:hover {
    background-color: #f0f0f0;
}

.design-info {
    padding: 10px;
}

.design-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.design-description {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 5px;
}

.design-tags {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    background-color: #e0e0e0;
    color: #333;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    margin-right: 5px;
    margin-bottom: 5px;
}

.design-details {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.hero-image-container {
    width: 100%;
}

.hero-image {
    width: 100%;
    height: auto;
}

.design-content {
    padding: 15px;
}

/* Dark button fill when a selection is made */
.designs-list li.selected .back-button {
    background: #2c2c2c;
    color: #fff;
}

.designs-list li.selected {
  background-color: #f0f0f0;
}

.tag.selected {
  background-color: #333;
  color: #fff;
}

.project-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .project-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .project-logo {
    max-width: 200px;
    margin-bottom: 20px;
  }
  
  .project-metadata {
    margin-bottom: 20px;
  }
  
  .project-metadata p {
    margin: 5px 0;
  }
  
  .back-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .project-section {
    margin-bottom: 30px;
  }
  
  .project-section h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .project-section p {
    line-height: 1.6;
  }
  
  .project-screenshot,
  .project-feature-image {
    max-width: 100%;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
 /* Mobile-specific styles */
@media (max-width: 768px) {
  .designs-container {
      flex-direction: column;
  }

  .designs-sidebar {
      flex: 0 0 auto;
      width: 100%;
      border-right: none;
      border-bottom: 2px solid #f7f7f7;
  }

  .design-details {
      height: auto;
  }

  .search-bar {
      width: 50%; /* Full width on mobile */
      padding: 20px; /* Slightly larger padding for better touch targets */
      margin-top: 40px;
      border: 1px solid #f90000;
      border-radius: 5px;
      font-size: 16px; /* Slightly larger font size for better readability on mobile */
      -webkit-appearance: none; /* Remove default styling on iOS */
      appearance: none;
  }


}

.logo-container {
  width: 100%;
  max-width: 1200px; /* Increase this value to make the container larger */
  margin: 0 auto;
  padding: 20px;
}

.argus-logo,
.timemachine-logo {
  width: 100%;
  height: auto;
  max-height: 600px; /* Adjust this value to set a maximum height */
  object-fit: contain; /* This ensures the image maintains its aspect ratio */
}

.hero-image-container {
  width: 100%;
}

.hero-image {
  width: 100%;
  height: auto;
}

.design-details {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.generic-design-container {
  padding: 20px;
}

.design-tags {
  margin-top: 10px;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 0.9em;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .designs-container {
    flex-direction: row; /* Revert to horizontal layout on larger screens */
  }

  .designs-sidebar {
    width: 300px;
    height: 100vh;
    max-height: none;
    border-right: 1px solid #e0e0e0;
    border-bottom: none;
    padding: 20px;
  }

  .hero-image-container {
    height: auto; /* Allow container to adjust based on content */
  }
}
