/* Importing the fonts */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-thin-rounded/css/uicons-thin-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-straight/css/uicons-solid-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-brands/css/uicons-brands.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.6.0/uicons-brands/css/uicons-brands.css');
/* Base styling for the page */

body {
  font-family: 'SF Mono', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';
  margin: 0;
  padding: 0; /* Remove default padding */
  background: #fff; /* Background color set to white */
  color: #333; /* Primary text color */
}

/* Heading and subheading styling */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif; /* Use Inter for all heading levels */
  font-weight: 700; /* Bold for better readability */
}

h1 {
  font-size: 24px; /* Larger text for main headings */
  margin-bottom: 10px; /* Spacing below the heading */
}

h2 {
  font-size: 20px; /* Smaller than h1 */
  margin-bottom: 8px;
}
h3 {
  font-size: 18px; /* Smaller than h1 */
  margin-bottom: 8px;
}

/* Link styling */
a {
  color: #737373; /* Blue color for links to stand out */
  text-decoration: none; /* No underline by default */
  font-weight: bold; /* Bold to highlight links */
}

a:hover, a:focus {
  text-decoration: underline; /* Underline on hover/focus for better interaction */
}

/* Icon size adjustment */
[class*="flaticon"] {
  font-size: 10px; /* Adjust this value to make icons smaller */
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
  body {
    padding: 10px; /* Less padding on smaller screens */
  }

  h1 {
    font-size: 12px; /* Slightly smaller font size on mobile */
  }

  p {
    font-size: 12px; /* Smaller font size for paragraphs on mobile */
  }

  .content {
    padding: 10px 5px; /* Adjusting padding within the content area */
  }
}
