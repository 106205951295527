

body {
  margin: 0;
  background-color: #f0f0f0;
}

.sidebar {
  background-color: #ffffff;
  width: 190px;
  height: 100vh;
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  border-right: 2px solid #f7f7f7;
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  
}

/* Container for the globe and text */
.globe {
  display: flex;
  align-items: center; /* Center items vertically */
  padding: 10px 4px;
}

/* Styling for the icon */
.globe i {
  font-size: 14px; /* Adjust size as needed */
  margin-right: 5px; /* Space between icon and text */
  display: flex; /* Ensure the icon is a flex container */
  align-items: center; /* Center the content inside the icon */
}

/* Styling for the text */
.globe .globe-text {
  font-family: 'SF Mono', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';
  font-size: 10px; /* Adjust size as needed */
  color: #333; /* Primary text color */
}

.content {
  margin-left: 150px;
  padding: 40px;
  box-sizing: border-box;
}

.menu-title {
  font-size: 12px;
  padding-bottom: 20px;
}

.menu-section {
  margin-bottom: 30px;
}

.menu-section h2, .menu-section h3 {
  color: #a8a8a8;
  margin: 0 0 10px 0;
  font-size: 12px;
  font-family: monospace;
}

.menu-section ul {
  list-style: none;
  padding: 0;
}

.menu-section ul li {
  margin-bottom: 10px;
  font-family: monospace;
}

.sidebar a {
  color: #2c2c2c;
  text-decoration: none;
  font-size: 12px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: none;
  font-family: 'Inter', sans-serif;
}

.sidebar a i, .sidebar a .icon {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #eeeeee;
  
  transition: box-shadow 0.3s, background-color 0.3s;
}

.sidebar a:hover {
  background-color: #f0f0f0;
  color: #2c2c2c;
}

.sidebar a.selected {
  background-color: #f0f0f0;
  color: #2c2c2c;
}

.sidebar a:hover i, .sidebar a:hover .icon, .sidebar a.selected i, .sidebar a.selected .icon {
  animation: breathing 6s ease-in-out infinite;
  background-color: #2c2c2c;
  color: #ffffff;
}

@keyframes breathing {
  0%, 100% {
    box-shadow: 0 0 8px rgba(0, 255, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 255, 0, 1);
  }
}

.menu-toggle {
  display: none;
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1001;
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
}

.menu-close {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .content {
    margin-left: 0;
    padding: 2px;
  }

  .menu-toggle {
    display: block;
  }

  .content.shifted {
    margin-left: 200px;
  }

  .menu-close {
    display: block;
  }
}

/* For the gradient logo */
.icon-container {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 10px; /* Add margin to align with other icons */
}
.icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}


.icon.light {
  opacity: 0;
}

a:hover .icon.light, a:focus .icon.light, a:active .icon.light {
  opacity: 1;
}

a:hover .icon.dark, a:focus .icon.dark, a:active .icon.dark {
  opacity: 0;
}

/* For the Sunny logo */

