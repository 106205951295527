/* Home container */
.home-container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 100px;
  background-color: #ffffff;
  background-image: radial-gradient(circle, #e2e2e2 1px, #ececec00 1px);
  background-size: 12px 12px;
}

/* Navigation section */
.home-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.nav-items {
  display: flex;
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 8px;
  padding: 8px;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-item.active {
  background-color: #1c1c1c;
  border-radius: 5px;
  color: white;
}

.nav-item.inactive {
  background-color: #F3F4F6;
  border-radius: 4px;
}

.nav-item-text {
  margin-left: 8px;
}

/* Social icons container */
.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.social-icon {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F3F4F6;
  border-radius: 5px;
  margin: 0 12px;
  transition: background-color 0.3s, transform 0.3s;
}

.social-icon i {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon:hover {
  background-color: #ddd;
  transform: scale(1.1);
}

/* Main content area */
.main-content {
  width: 100%;
  background: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Profile image */
.profile-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Content text */
.main-content p {
  text-align: left;
  margin: 0 0 10px;
  line-height: 1.6;
  font-size: 14px;
}

/* Heading styling */
h1, h2, h3 {
  font-weight: 700;
  margin: 0 0 10px;
}

h1 { font-size: 24px; }
h2 { font-size: 20px; }
h3 { font-size: 18px; }

/* Link styling */
.main-content a {
  color: #454545;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  padding: 1px 4px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.main-content a:hover,
.main-content a:focus {
  text-decoration: underline;
}

/* Icon size adjustment */
[class*="flaticon"] {
  font-size: 10px;
}

/* Work experience section */
.work-experience {
  margin-top: 14px;
  text-align: left;
}

.work-experience ul {
  list-style: none;
  padding: 0;
}

.work-experience li {
  margin-bottom: 10px;
}

/* Company info styling */
.company-info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}

.company-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
  border-radius: 4px;
}

.company-info > div {
  display: flex;
  flex-direction: column;
}

.company {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.company-link {
  text-decoration: none;
}

.company-link:hover {
  text-decoration: underline;
}

.location {
  background-color: #F3F4F6;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 10px;
  margin-left: 10px;
}

.stack {
  background-color: #F3F4F6;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 10px;
  margin-left: 10px;
}
.role {
  font-style: italic;
  font-size: 14px;
  color: #000000;
  margin-bottom: 6px;
}

.dates {
  color: #434343;
  font-size: 12px;
  margin-bottom: 6px;
}

.about {
  font-size: 13px;
  color: #6a6a6a;
  margin-bottom: 10px;
}

/* List layout */
.work-list,
.advisory-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.work-list li,
.advisory-list li {
  width: 48%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .home-container {
    padding: 20px;
  }

  .main-content {
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  .profile-image {
    max-width: 100%;
    margin-bottom: 15px;
  }

  h1 { font-size: 22px; }
  h2 { font-size: 18px; }
  h3 { font-size: 16px; }

  p {
    font-size: 12px;
    margin: 0 0 10px;
  }

  .home-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-items {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .nav-item {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
  }

  .nav-item.active,
  .nav-item.inactive {
    border-radius: 5px;
  }

  .work-list,
  .advisory-list {
    flex-direction: column;
  }

  .work-list li,
  .advisory-list li {
    width: 100%;
  }

  .company, .role, .about {
    font-size: 12px;

  }

  .company-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-logo {
    margin-right: 0;
    margin-bottom: 6px;
  }

  .company-info > div {
    margin-left: 0;
  }

  .dates {
    margin-top: 5px;
    font-size: 11px;
  }

  .location {
    margin-left: 0;
    margin-top: 5px;
  }

  .social-icons {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .social-icon {
    margin: 0 8px 0 0;
  }
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
}

.link-button:hover {
  color: #551A8B;
}

.nav-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  transition: background-color 0.3s ease;
  vertical-align: baseline;
}

.nav-button:hover {
  background-color: #555;
}

.work-experience p {
  line-height: 1.6;
}

.blur-up {
  filter: blur(5px);
  transition: filter 0.3s ease-out;
}

.blur-up.loaded {
  filter: blur(0);
}

.design-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.uiux-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.uiux-item {
  margin-bottom: 2px;
}

.uiux-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 10px;
  object-fit: cover;
}

.uiux-content {
  padding: 8px 0;
}

.uiux-content .company {
  display: block;
  font-size: 1.2em;
  margin-bottom: 2px;
}

.uiux-content .stack {
  font-size: 0.9em;
  color: #666;
  margin-left: 10px;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: #f5f5f5;
}

.uiux-content .about {
  display: block;
  color: #666;
  margin-top: 4px;
}

.green-dot {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background-color: #32CD32; /* LimeGreen color */
  border-radius: 50%;
  box-shadow: 0 0 5px #32CD32;
}

.grey-dot {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background-color: #b3b3b3; /* Orange color */
  border-radius: 50%;
  box-shadow: 0 0 5px #b3b3b3;
}


.black-dot {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background-color: #171717; /* LimeGreen color */
  border-radius: 50%;
  box-shadow: 0 0 5px #171717;
}


.red-dot {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  background-color: #ff0000; /* LimeGreen color */
  border-radius: 50%;
  box-shadow: 0 0 5px #ff0000;
}

.subheading {
  color: #4a4a4a; /* Slightly black/grey color */
}
